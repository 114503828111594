@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  font-family: inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.title {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes rotate-animation {
  0% {
    transform: rotate(0deg) translateX(-50%);
  }

  100% {
    transform: rotate(360deg) translateX(-50%);
  }
}

.circle {
  position: absolute;
  top: 50%;
  /* Center the circle */
  left: 50%;
  /* Center the circle */
  width: 20px;
  /* Your circle size */
  height: 20px;
  /* Your circle size */
  margin-top: -10px;
  /* Half of your height */
  margin-left: -10px;
  /* Half of your width */
  border-radius: 50%;
  background-color: your-color-here;
  animation: rotate-animation 10s infinite linear;
  /* Adjust timing as needed */
}

/* You would add additional CSS classes or elements for each color and adjust the animation-delay to offset them */